import Vue from 'vue'
import BasicLayout from '@/layouts/BasicLayout';
import HomeView from '@/views/home/HomeView';
import PersonalCenter from '@/views/personalCenter/PersonalCenter';
import ProductList from '@/views/productList/ProductList';
import ProductDetails from '@/views/productDetails/ProductDetails';
import Buy from '@/views/buy/Buy';
import Success from '@/views/buy/Success';
import Register from '@/views/user/register';
import Helper from '@/views/help/Helper';
import Course from '@/views/course/Course';
import VueRouter from 'vue-router';
import CommentGoods from "@/views/personalCenter/commentGoods";
import Chat from "@/views/online/Chat";
import Graduation from "@/views/graduation/graduation.vue";

Vue.use(VueRouter)

// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }
const routes = [
    {
        path: '/',
        name: 'index',
        component: BasicLayout,
        children: [
            {path: '', component: HomeView},
            {name: 'productlist',path: '/productlist', component: ProductList},
            // {name: 'productlist',path: '/productlist/:code', component: ProductList},
            {path: '/my', component: PersonalCenter},
            {path: '/details', component: ProductDetails},
            {path: '/comment/goods', component: CommentGoods},
            {path: '/buy', component: Buy},
            {path: '/register', component: Register},
            {path: '/success', component: Success},
            {path: '/help', component: Helper},
            {path: '/course', component: Course},
            { path: '/graduation', component: Graduation }
        ],
    },
    {
        path: '/chat/online',
        name: 'chat',
        component: Chat
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: () => ({ y: 0 }), //滚动到顶端
    routes
})

export default router
