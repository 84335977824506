import router from './router'
import store from './store'
import {getToken, removeToken} from "@/utils/auth";
import Vue from "vue";
import {Notification} from 'element-ui';
import cache from "@/plugins/cache";
import {commit} from "lodash/seq";

router.beforeEach((to, from, next) => {
    let permitThrough = true;
    if (to.path == '/my') {
        const token = getToken();
        if (!token) {
            permitThrough = false;

        }
    }
    if (permitThrough) {
        next();
    } else {
        cache.local.remove("store")
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        commit('SET_NICK_NAME', '')
        commit('SET_MAIL', '')
        commit('SET_PERMISSIONS', [])
        removeToken()
        location.href = '/';
        // Notification({
        //     title: '警告',
        //     message: '请先登录',
        //     type: 'warning',
        //     offset: 100,
        //     duration: 1000
        // });
    }
})
