import request from '@/utils/request'

const orderApi = {
    postAlipay: '/customer/order/alipay',
    syncOrder: '/customer/order/sync',
    findOrder: '/customer/order/find',
    selfOrder: '/shop/order/list/self',
    baseApi: '/shop/order',
}


/**
 * 付款
 * @param parameter
 * @returns {AxiosPromise}
 */
export function findOrder(parameter) {
    return request({
        url: orderApi.findOrder,
        method: 'get',
        params: parameter,
        headers: {
            isToken: false
        },
        timeout: 20000
    })
}

/**
 * 删除订单
 * @param parameter
 * @returns {AxiosPromise}
 */
export function delOrder(parameter) {
    return request({
        url: orderApi.baseApi + "/" + parameter,
        method: 'delete',
        headers: {
            isToken: true
        }
    })
}

/**
 * 付款
 * @param parameter
 * @returns {AxiosPromise}
 */
export function postAlipay(parameter) {
    return request({
        url: orderApi.postAlipay,
        method: 'post',
        data: parameter,
        headers: {
            isToken: false
        },
        timeout: 20000
    })
}

/**
 * 继续付款
 */
export function continueAlipay(orderCode) {
    return request({
        url: orderApi.postAlipay + "/" + orderCode,
        method: 'post',
        headers: {
            isToken: false
        },
        timeout: 20000
    })
}

/**
 * 付款
 * @param parameter
 * @returns {AxiosPromise}
 */
export function syncOrder(data) {
    return request({
        url: orderApi.syncOrder,
        method: 'get',
        params: data,
        headers: {
            isToken: false
        },
        timeout: 20000
    })
}

/**
 * 查询个人订单数据
 * @param parameter
 * @returns {AxiosPromise}
 */
export function getMyHistoryOrder(parameter) {
    return request({
        url: orderApi.selfOrder,
        method: 'get',
        params: parameter,
        headers: {
            isToken: true
        },
        timeout: 20000
    })
}

/**
 * 单个查询
 * @param parameter
 * @returns {AxiosPromise}
 */
export function getOrderByCode(parameter) {
    return request({
        url: orderApi.baseApi + "/" + parameter,
        method: 'get',
    })
}

// 上传评论图片
export function uploadsComment(data) {
    return request({
        url: '/common/comment/uploads',
        method: 'post',
        data: data,
        isFile: true
    })
}