<template>
  <div class="personal-setting-wrap">
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
<!--      <el-form-item label="用户名" prop="name">-->
<!--        <el-col :span="11">-->
<!--        <el-input v-model="form.name"></el-input>-->
<!--        </el-col>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="邮箱" prop="email">-->
<!--        <el-col :span="11">-->
<!--          <el-input v-model="form.email"></el-input>-->
<!--        </el-col>-->
<!--      </el-form-item>-->
      <el-form-item label="旧密码" prop="oldPwd">
        <el-col :span="11">
          <el-input v-model="form.oldPwd"  type="password"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="新密码" prop="newPwd">
        <el-col :span="11">
          <el-input v-model="form.newPwd"  type="password"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="确认密码" prop="newPwd">
        <el-col :span="11">
          <el-input v-model="form.twoPwd"  type="password"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('form')">确认</el-button>
        <el-button @click="resetForm('form')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {updateUserPwd} from "@/api/user";

export default {
  name: "PersonalSetting",
  data() {
    return {
      form: {
        // name: '',
        // email: '',
        oldPwd: '',
        newPwd: ''
      },
      rules: {
        oldPwd: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6,  message: '密码长度须大于6位', trigger: 'blur' }
        ],
        newPwd: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6,  message: '密码长度须大于6位', trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //有新密码，才将旧密码新密码一起提交
          if(this.form.newPwd!=this.form.twoPwd){
            this.$modal.msgError("两次密码不一致");
            return ;
          }
          updateUserPwd(this.form.oldPwd,this.form.newPwd).then(res=>{
            this.$modal.msgSuccess(res.msg);
          })

        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>

<style scoped>
.personal-setting-wrap{
  margin-top: 100px;
}
</style>
