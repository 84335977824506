<template>
  <div>
    <!--====== Banner Start ======-->
    <section class="breadcrumb-area">
      <div class="container">
        <div class="content">
          <h2 class="breadd  wow fadeInUp" id="title-1">
            毕设一对一
          </h2>
          <p class="text wow fadeInUp" style="color: #FFFFFF" id="title-1">
            我们本着不抛弃、不放弃的原则，为您提供优质的项目开发、提供耐心认真的技术、答辩咨询指导服务，协助您顺利学业，为大学生涯画上一个完美句号！
          </p>
          <ul class="breadcrumb-list  wow fadeInUp">
            <li><a>首页 /</a></li>
            <li>毕设一对一</li>
          </ul>
        </div>
      </div>
    </section>
    <el-row style="margin-top: 10px">
      <el-col :span="6">
        &nbsp;
      </el-col>
      <el-col :span="12" class="course-content">
        <h4>一、服务介绍</h4>
        <div>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;经过不断得行业积累以及教育经验，深知学生在毕业过程中遇到的问题，我们本着协助学生完成毕业设计、毕业论文的同时，<font
              color="#dc143c">尽可能传输给学生技术、答辩以及学习各方面技巧，做到知无不严、不厌其烦。</font>
            我们的收费可能不是最便宜的，但是绝对是性价比，针对客户不存在滥竽充数，<font
                color="#dc143c">保质保量完成项目，力争成为客户毕业季最坚实的后盾。</font></p> <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们见过很多客户案例，收费后发一个破烂项目不符合要求，
            收费后针对技术项目问题回复率极低，甚至会出现收取定金后跑路行为。我和你们一样很痛恨这种行为，首先作为学生没有富足的钱，其次毕业那么紧张的氛围还增添烦恼与焦虑。我们能<font
                color="#dc143c">保证不跑路、积极及时回复解决客户问题、
              保质保量完成客户需求，力争做客户毕业季的自信来源。</font></p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;虽然我们以高质量产品和服务为主，但是不接受傻瓜式提问，需要你自备一定思考能力，而不是全部依赖，百度一下，或许你知道的更多。如果经过思考后，还存在疑问，欢迎提问，我必尽我所能。</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<font color="#dc143c">都是从学生过来的，希望我们可以成为你可靠的后盾！</font></p>
          <h6><strong>项目：定制毕设项目</strong></h6>
          <ol start=''>
            <li>1.&nbsp;提供全网唯一的项目开发定制，且一年内不会公布</li>
            <li>2.&nbsp;提供前期项目功能规划交流以及调研服务</li>
            <li>3.&nbsp;提供项目远程安装部署运行服务</li>
            <li>4.&nbsp;提供后期项目功能、调整、修改（需合理、合规、符合常规开发要求）</li>
          </ol>
          <h6><strong>项目：毕业论文</strong></h6>
          <ol start=''>
            <li>1.&nbsp;提供全网唯一的且符合查重规定的论文，且一年内不会公布</li>
            <li>2.&nbsp;提供论文初稿以及2次论文终稿修改（足以应付常规毕业）</li>
          </ol>
        </div>

        <h4>二、参考价格</h4>
        <template>
          <p class="text wow fadeInUp" style="color: #000000" id="title-1">
            为了保证项目质量、服务质量，我们会在订单饱和后停止接单，因此我们没有团购优惠价，任然有很多小伙伴相互介绍，在这里特此感谢。
          </p>
          <el-table
              :data="tableData"
              border
              style="width: 450.5px">
            <el-table-column
                prop="v2"
                label="定制毕设项目"
                width="150">
            </el-table-column>
            <el-table-column
                prop="v3"
                label="毕业论文"
                width="150">
            </el-table-column>
            <el-table-column
                prop="v4"
                label="价格"
                width="150">
            </el-table-column>
          </el-table>
        </template>
        <h4>三、联系方式</h4>
        微信号：<font color="#dc143c">amcloud_os</font>(添加请备注)

        <br>
        <img style="width: 300px;margin-left: 60pt" src="../../assets/images/vx.png">

        <h4>四、服务案例</h4>

        <el-carousel :interval="4000" type="card" height="600px" style="text-align: center">
          <el-carousel-item key="1">
            <img style="height: 600px" src="../../assets/case/1.jpg">
          </el-carousel-item>
          <el-carousel-item key="2">
            <img style="height: 600px" src="../../assets/case/2.jpg">
          </el-carousel-item>
          <el-carousel-item key="3">
            <img style="height: 600px" src="../../assets/case/3.jpg">
          </el-carousel-item>
          <el-carousel-item key="4">
            <img style="height: 600px" src="../../assets/case/4.jpg">
          </el-carousel-item>
          <el-carousel-item key="5">
            <img style="height: 600px" src="../../assets/case/5.jpg">
          </el-carousel-item>
          <el-carousel-item key="6">
            <img style="height: 600px" src="../../assets/case/6.jpg">
          </el-carousel-item>
          <el-carousel-item key="7">
            <img style="height: 600px" src="../../assets/case/7.jpg">
          </el-carousel-item>
          <el-carousel-item key="8">
            <img style="height: 600px" src="../../assets/case/8.jpg">
          </el-carousel-item>
          <el-carousel-item key="9">
            <img style="height: 600px" src="../../assets/case/9.jpg">
          </el-carousel-item>
          <el-carousel-item key="10">
            <img style="height: 600px" src="../../assets/case/10.jpg">
          </el-carousel-item>
        </el-carousel>

      </el-col>
      <el-col :span="6">
        &nbsp;
      </el-col>
    </el-row>
  </div>
</template>


<script>
export default {
  data() {
    return {
      tableData: [
       {
          v2: '√',
          v3: ' ',
          v4: '1500±',
        }, {
          v2: ' ',
          v3: '√',
          v4: '1500±',
        }, {
          v2: '√',
          v3: '√',
          v4: '3000±',
        }
      ],
      caseList: []
    };
  },
  mounted() {
    for (let i = 1; i <= 7; i++) {
      this.caseList.push('')
    }
  },
  methods: {}
}
</script>
<style scoped>
.q-title {
  font-weight: bold;
  font-size: 16px;
}

.course-content {
  line-height: 2;
}

.course-content h4 {
  margin-top: 40pt;
  margin-bottom: 20pt;
}

/deep/ table {
  margin: 0 !important;
}
</style>
