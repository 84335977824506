import request from '@/utils/request'

const userApi = {
    registerLogin: '/register/login',
    login: '/customer/login',
    register: '/register',
    getCaptImg: '/captchaImage',
    sendEmailCode: '/mail/captcha',
    getUserInfo: '/getCustomerInfo',
    logout: '/logout',
    forgetPass: '/retrieve'
}

/**
 * 获取用户信息
 * @param parameter
 * @returns {AxiosPromise}
 */
export function getInfo() {
    return request({
        url: userApi.getUserInfo,
        method: 'get'
    })
}


/**
 * 登录接口
 * @param parameter
 * @returns {AxiosPromise}
 */
export function login(username, password, code, uuid) {
    const data = {
        username,
        password,
        code,
        uuid
    }
    return request({
        url: userApi.login,
        method: 'post',
        data: data,
        headers: {
            isToken: false
        },
    })
}

/**
 * 登录接口
 * @param parameter
 * @returns {AxiosPromise}
 */
export function registerLogin(username, password) {
    const data = {
        username,
        password
    }
    return request({
        url: userApi.registerLogin,
        method: 'post',
        data: data,
        headers: {
            isToken: false
        },
    })
}

/**
 * 注册数据提交
 * @param parameter
 * @returns {AxiosPromise}
 */
export function sendEmailCode(parameter) {
    return request({
        url: userApi.sendEmailCode,
        method: 'get',
        params: parameter,
        headers: {
            isToken: false
        },
        timeout: 20000
    })
}


/**
 * 获取图片验证码
 * @param parameter
 * @returns {AxiosPromise}
 */
export function getCaptImg() {
    return request({
        url: '/captchaImage',
        headers: {
            isToken: false
        },
        method: 'get',
        timeout: 20000
    })
}


/**
 * 注册数据提交
 * @param parameter
 * @returns {AxiosPromise}
 */
export function register(parameter) {
    return request({
        url: userApi.register,
        method: 'post',
        data: parameter,
        headers: {
            isToken: false
        },
        timeout: 20000
    })
}

/**
 * 找回密码
 * @param parameter
 * @returns {AxiosPromise}
 */
export function forgetPass(parameter) {
    return request({
        url: userApi.forgetPass,
        method: 'put',
        data: parameter,
        headers: {
            isToken: false
        },
        timeout: 20000
    })
}

// 退出方法
export function logout() {
    return request({
        url: userApi.logout,
        method: 'post'
    })
}


// 修改用户个人信息
export function updateUserProfile(data) {
    return request({
        url: '/system/user/profile',
        method: 'put',
        data: data
    })
}

// 用户密码重置
export function updateUserPwd(oldPassword, newPassword) {
    const data = {
        oldPassword,
        newPassword
    }
    return request({
        url: '/system/user/profile/updatePwd',
        method: 'put',
        params: data
    })
}

// 用户头像上传
export function uploadAvatar(data) {
    return request({
        url: '/system/user/profile/avatar',
        method: 'post',
        data: data
    })
}
