<template>
  <div class="home">
    <!--====== Preloader Start ======-->
    <div id="preloader" style="display: none;">
      <div class="loader-cubes">
        <div class="loader-cube1 loader-cube"></div>
        <div class="loader-cube2 loader-cube"></div>
        <div class="loader-cube4 loader-cube"></div>
        <div class="loader-cube3 loader-cube"></div>
      </div>
    </div>
    <!--====== Preloader End ======-->
    <a-m-header :showHeaderBg="showHeaderBg"/>
    <service></service>
    <div class="layout-content">
      <router-view></router-view>
    </div>
    <a-m-footer/>
  </div>
</template>

<script>
import AMHeader from "@/components/header/AMHeader";
import AMFooter from "@/components/footer/AMFooter";
import Service from "@/components/robot/Service";


export default {
  name: 'BasicLayout',
  components: {
    AMHeader,
    Service,
    AMFooter
  },
  data() {
    return {
      showHeaderBg: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      new this.$wow.WOW().init();
    });

  }
}
</script>
<style scoped>
.home {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
