import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import VueStorage from 'vue-ls'
import plugins from './plugins' // plugins
import './permission' // permission control
import {
    parseTime,
    resetForm,
    addDateRange,
    selectDictLabel,
    selectDictLabels,
    handleTree,
    changeBlob,
    numberToFixed
} from "@/utils/shiyuncode";
//md文档编辑器
import mavonEditor from 'mavon-editor';
// 全局方法挂载
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.handleTree = handleTree
Vue.prototype.changeBlob = changeBlob
Vue.prototype.numberToFixed = numberToFixed

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueMeta)
Vue.use(plugins)
Vue.use(mavonEditor);
Vue.use(VueStorage)
import $ from 'jquery'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
// import '@/static/js/jquery.nice-select.min.js'
import '@/static/js/jquery.magnific-popup.min.js'
import "mavon-editor/dist/css/index.css"

import "animate.css"
import "wowjs/css/libs/animate.css"// 巨坑... 此地方要引入两个css
import wow from 'wowjs'
// 调用自定义指令
Vue.prototype.$wow = wow
import '@/static/js/mixitup.min.js'
import '@/static/js/main.js'
import VueMeta from "vue-meta";

Vue.directive('title', {//单个修改标题
    inserted: function (el, binding) {
        document.title = el.dataset.title
    }
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

