import request from '@/utils/request'

const commentApi = {
    baseURL: "/shop/comment",
    checkURL: "/shop/comment/check",
    goodsCommentListURL: "/customer/goods/comments",
}

/**
 * 新增评论
 * @param data
 * @returns {AxiosPromise}
 */
export function pushComment(data) {
    return request({
        url: commentApi.baseURL,
        method: 'post',
        data: data,
        isFile: false
    })
}

/**
 * 校验是否允许评论
 * @param data
 * @returns {AxiosPromise}
 */
export function checkComment(code) {
    return request({
        url: commentApi.checkURL + "/" + code,
        method: 'get'
    })
}

/**
 * 校验是否允许评论
 * @param data
 * @returns {AxiosPromise}
 */
export function getCommentList(parameter) {
    return request({
        url: commentApi.goodsCommentListURL,
        method: 'get',
        params: parameter,
        headers: {
            isToken: false
        },
        timeout: 20000
    })
}

