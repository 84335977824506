import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import user from './modules/user'

import getters from './getters'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        app,
        user
    },
    /* vuex数据持久化配置 */
    plugins: [
        createPersistedState({
            // 存储方式：localStorage、sessionStorage、cookies
            storage: window.localStorage,
            // 存储的 key 的key值
            key: "store",
            reducer(state) { //render错误修改
                // 要存储的数据：本项目采用es6扩展运算符的方式存储了state中所有的数据
                return {...state};
            }
        })
    ],
    getters,
})

export default store
