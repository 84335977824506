<template>
  <div>
    <el-card class="box-card" style="width: 700px;margin: 200px auto">
      <div slot="header" class="clearfix">
        <svg t="1651200556140" class="icon" viewBox="0 0 1024 1024" version="1.1"
             xmlns="http://www.w3.org/2000/svg"
             p-id="1519" width="40" height="40" style="margin-right: 10px">
          <path
              d="M512 972.8a460.8 460.8 0 1 0 0-921.6 460.8 460.8 0 0 0 0 921.6z m0 51.2C229.2224 1024 0 794.7776 0 512S229.2224 0 512 0s512 229.2224 512 512-229.2224 512-512 512z m320-776.96l13.824 16.896a27.1872 27.1872 0 0 0-38.4 0l-411.5456 413.696-156.7744-162.304a29.44 29.44 0 0 0-40.2432 0c-10.5472 10.5472-8.704 27.8528 1.792 38.6048l176.2304 181.76a27.1872 27.1872 0 0 0 40.96-3.072l428.1344-430.1824a27.1872 27.1872 0 0 0-0.1536-38.5024l-13.824-16.896z"
              p-id="1520" fill="#79c80e"></path>
        </svg>

        <span class="success-title">恭喜您,订单已经支付成功！<span v-if="orderForm.serviceStatus==1">远程核销码：<span
            style="color: red"
            class="item-order">{{ orderForm.serviceCode }}</span>
            </span></span>
      </div>
      <el-row>
        <el-col :span="18">
          <div class="grid-content bg-purple">
            <div><i class="el-icon-tickets"></i>订单编号：<span class="item-order">{{ orderForm.orderCode }}</span>
            </div>
            <div><i class="el-icon-message"></i>下单邮箱：<span class="item-order">{{ orderForm.userMail }}</span>
            </div>
            <div><i class="el-icon-monitor"></i>项目名称：<span class="item-order">{{ orderForm.goodsName }}</span>
            </div>
            <div><i class="el-icon-coin"></i>订单金额：<span class="item-order"
                                                            style="">{{ numberToFixed(orderForm.orderPrice, 2) }}</span>元
            </div>
            <div><i class="el-icon-date"></i>订单时间：<span
                class="item-order">{{ parseTime(orderForm.payTime) }}</span>
            </div>

            <div style="width: 120%"><i class="el-icon-connection"></i>下载地址：<a class="item-order"
                                                                                   :href="orderForm.url"
                                                                                   target="_blank">{{orderForm.url}}</a>
            </div>
            <div><i class="el-icon-key"></i>下载密钥：<span class="item-order">{{ orderForm.key }}</span></div>

            <div><i class="el-icon-phone"></i>客服微信：<span class="item-order">【amcloud_os】</span>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple-light">
            <img src="../../assets/images/success.jpg" style="width:100%;margin-top: 20px">
          </div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {syncOrder} from '../../api/order';

export default {
  name: 'Success',
  created() {
    this.initForm();
  },
  data() {
    return {
      orderForm: {
        orderCode: '',
        userMail: '',
        goodsName: '',
        payTime: '',
        orderPrice: '',
        serviceStatus: 0,
        serviceCode: '',
        url: '',
        key: '',
      }
    }
  },
  methods: {
    initForm() {
      const outTradeNo = this.$route.query.out_trade_no;
      const sign = this.$route.query.sign;
      if (outTradeNo == undefined || outTradeNo == '' || sign == undefined || sign == '') {
        this.$modal.msgError('非法参数，无法加载付款成功')
      } else {
        //同步调用订单完成接口
        console.log(this.$route.query)
        syncOrder(this.$route.query).then(res => {
          this.orderForm.orderCode = res.order.orderCode;
          this.orderForm.userMail = res.order.userMail;
          this.orderForm.goodsName = res.order.goodsName;
          this.orderForm.orderPrice = res.order.orderPrice;
          this.orderForm.payTime = res.order.payTime;
          this.orderForm.serviceStatus = res.order.serviceStatus;
          this.orderForm.serviceCode = res.order.serviceCode;
          this.orderForm.url = res.spDownload.downloadUrl;
          this.orderForm.key = res.spDownload.downloadKey;
        })
      }

    }

  },

}
</script>

<style scoped>
p {
  margin-bottom: 0px;
}

/deep/ .el-form-item__content {
  width: 90%;
}

/deep/ .el-form-item__label {
  width: 10%;
}

.item-order {
  margin-left: 10px;
  margin-bottom: 10px;
}

.success-title {
  font-weight: bold;
  font-size: 20px;
}

</style>
