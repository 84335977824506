<template>
  <div>
    <!--====== Banner Start ======-->
    <section class="breadcrumb-area">
      <div class="container">
        <div class="content">
          <h2 class="breadd  wow fadeInUp" id="title-1">
            环境安装
          </h2>
          <ul class="breadcrumb-list  wow fadeInUp">
            <li><a>首页 /</a></li>
            <li>环境安装</li>
          </ul>
        </div>
      </div>
    </section>
    <el-row style="margin-top: 10px;width: 60%;margin:0px auto">
      <el-col :span="24" class="course-content">
        <el-card>
          <h4>一、安装简介</h4>
          <p>
            本项目为大家提供免费的安装包以及配套的视频教程，协助各位小伙伴成功安装软件开发所需的基础环境。教程以windows11为例，同样windows10及以下版本任然适用。本安装教程仅限学习使用，切勿传播，否则将面临第三方等带来的法律风险。</p>
          <h4>二、获取地址</h4>
          <ul>
            <li>百度网盘：<a href="https://pan.baidu.com/s/1tH6g1gna7HoeJqNRVULqCA?pwd=md59" target="_blank">https://pan.baidu.com/s/1tH6g1gna7HoeJqNRVULqCA?pwd=md59</a>
            </li>
            <li>下载密钥：md59</li>
          </ul>
          <h4>三、教程内容</h4>
          <div>
            教程提供以下两种开发环境的安装:
            <ul>
              <li>JAVA开发所需软件以及环境：JDK、IDEA、MySQL、Navicat、Maven</li>
              <li>Python开发所需软件以及环境：Python、Pycharm、MySQL、Navicat</li>
            </ul>
            分享内容包括教学视频+安装包,安装的顺序本质没有要求，可以先环境后软件。按照个人喜好或者一下序号进行安装即可。
            <img src="../../assets/images/course_1.png">
            <img src="../../assets/images/course_2.png">
            <img src="../../assets/images/course_3.png">

          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>


<script>
export default {
  data() {
    return {};
  },
  methods: {}
}
</script>
<style scoped>
.q-title {
  font-weight: bold;
  font-size: 16px;
}

.course-content {
  line-height: 2;
}

.course-content h4 {
  margin-top: 20px;
  margin-bottom: 10px;
}
</style>
