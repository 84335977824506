import request from '@/utils/request'

const goodApi = {
  getCategory: '/customer/category/list',
  getGoods: '/customer/goods/list',
  getRandomGoods: '/customer/goods/random',
  getGoodsByCode: '/customer/goods',
  getMyHistoryGoods: '/shop/goods/list/self',
  getGoodDownload: '/shop/goods/download/',
  getGroupGoodsUrl: '/customer/goods/group/',
}

/**
 * 单个查询
 * @param parameter
 * @returns {AxiosPromise}
 */
export function getGoodsByCode(parameter) {
  return request({
    url: goodApi.getGoodsByCode + '/' + parameter,
    method: 'get',
    headers: {
      isToken: false
    },
  })
}

/**
 * 单个查询
 * @param parameter
 * @returns {AxiosPromise}
 */
export function getGoodDownload(parameter) {
  return request({
    url: goodApi.getGoodDownload + parameter,
    method: 'get',
  })
}

/**
 * 查询数据
 * @param parameter
 * @returns {AxiosPromise}
 */
export function getGoods(parameter) {
  return request({
    url: goodApi.getGoods,
    method: 'get',
    params: parameter,
    headers: {
      isToken: false
    },
    timeout: 20000
  })
}

/**
 * 查询推荐数据
 * @param parameter
 * @returns {AxiosPromise}
 */
export function getRandomGoods(goodsCode) {
  return request({
    url: goodApi.getRandomGoods + '/' + goodsCode,
    method: 'get',
    headers: {
      isToken: false
    },
    timeout: 20000
  })
}

/**
 * 查询分类数据
 * @returns {AxiosPromise}
 */
export function getCategory() {
  return request({
    url: goodApi.getCategory,
    method: 'get',
    headers: {
      isToken: false
    },
    timeout: 20000
  })
}

/**
 * 查询数据
 * @param parameter
 * @returns {AxiosPromise}
 */
export function getMyHistoryGoods(parameter) {
  return request({
    url: goodApi.getMyHistoryGoods,
    method: 'get',
    params: parameter,
    headers: {
      isToken: true
    },
    timeout: 20000
  })
}

/**
 * 查询分组数据
 * @param parameter
 * @returns {AxiosPromise}
 */
export function getGroupGoods(parameter) {
  return request({
    url: goodApi.getGroupGoodsUrl + parameter,
    method: 'get',
    headers: {
      isToken: false
    },
    timeout: 20000
  })
}
