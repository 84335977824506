<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import cache from "@/plugins/cache"

export default {
  name: 'app',
  created() {
    if (cache.local.get("store")) {
      // 在页面加载时读取sessionStorage
      this.$store.replaceState(Object.assign({}, this.$store.state, cache.local.get("store")))
    }
  }
}
</script>
<style lang="scss">
@import "./static/css/magnific-popup.css";
//@import "./static/css/nice-select.css";
@import "./static/css/font-awesome.min.css";
@import "./static/css/style.css";
@import "./static/css/shiyuncode.css";
@import "./static/css/responsive.css";

.el-dialog {
  min-width: 500px;
}

.el-tabs__item {
  font-weight: bold;
}
</style>
