<template xmlns="http://www.w3.org/1999/html">
  <div>
    <div class="row" id="freeOS">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="i.name" :name="i.name" v-for="i in activeNameList" :key="i.value">
          <el-card v-for="commodity in goodsList" :key="commodity.id"
                   class="col-xl-12 wow fadeInUp text-center"
                   style="visibility: visible; animation-name: fadeInUp;">
            <el-row class="details-header">
              <div class="order-time">下单时间：{{ commodity.createTime }}</div>
              <div class="order-no">订单号：{{ commodity.orderCode }}</div>
              <div class="order-opt"><i class="el-icon-delete" @click="handleDelete(commodity.orderCode)"></i></div>
            </el-row>
            <el-row class="details-content">
              <div class="details-img-name" @click="toDetails(commodity.goodsCode)">
                <div style="margin: 5px">
                  <img
                      style="width: 120px; height: 70px"
                      :src="commodity.imgPath"/>
                </div>
                <div><p>{{ commodity.goodsName }}</p></div>
              </div>
              <div class="details-price">
                <p style="text-align: center">￥{{ commodity.orderPrice }}<br><span style="color: rgba(180, 180, 180);"
                                                                                   v-if="commodity.serviceStatus!=0">
                    （含远程费：{{ commodity.servicePrice }}元）</span></p>
              </div>
              <div class="details-mail"><p>{{ commodity.userMail }}</p></div>
              <div class="details-remark">
                <p>
                  <el-popover
                      placement="top-start"
                      width="auto"
                      trigger="hover"
                      :disabled="!(commodity.remark&&commodity.remark.length > 5)"
                      :content="commodity.remark">
                    <span slot="reference">{{
                        commodity.remark ? commodity.remark.length > 5 ? commodity.remark.substr(0, 5) + "..." : commodity.remark : '--'
                      }}</span>
                  </el-popover>
                </p>
              </div>
              <div class="details-status">
                <p v-if="commodity.status==1">未完成</p>
                <p v-if="commodity.status==2&&commodity.serviceStatus==1">待远程</p>
                <p v-if="commodity.status==2&&commodity.serviceStatus!=1">已完成</p>
              </div>
              <div class="details-opt">
                <p style="text-align: center;width: 70px;margin-right: 20px;">
                  <a @click="handleComment(commodity.orderCode)" v-if="commodity.status==2&&commodity.commentStatus!=1">
                    立即评价
                  </a>
                  <a @click="handleAfterSale(commodity.orderCode)"
                     v-if="commodity.status==2&&commodity.serviceStatus!=1">
                    联系售后
                  </a>
                  <a @click="handleAfterSale(commodity.orderCode)"
                     v-if="commodity.status==2&&commodity.serviceStatus==1">
                    售后&nbsp;|&nbsp;远程
                  </a>

                  <a @click="handleDownload(commodity.goodsCode)"
                     v-if="commodity.status==2">
                    立即下载
                  </a>

                  <a v-if="commodity.status==1" @click="handleAlipay(commodity.orderCode)">
                    继续支付
                  </a>
                </p>
              </div>

            </el-row>


          </el-card>
        </el-tab-pane>
      </el-tabs>


    </div>
    <template v-if="totalCount>0">
      <el-pagination
          class="pagination"
          background
          layout="prev, pager, next"
          @current-change="pageNoHandle"
          :page-count="totalPage">
      </el-pagination>
    </template>
    <template v-else>
      <el-empty :image-size="100"></el-empty>
    </template>
    <after-sale ref="afterSale"></after-sale>
    <div ref="alipayWap" v-html="alipay"/>
  </div>
</template>

<script>
import AfterSale from "@/views/personalCenter/modules/page/AfterSale.vue";
import {delOrder, getMyHistoryOrder, continueAlipay} from "@/api/order";
import {checkComment} from "@/api/comment";
import store from "@/store";
import {getGoodDownload} from "@/api/good";

export default {
  name: 'HistoryProject',
  components: {AfterSale},
  data() {
    return {
      baseUrl: process.env.VUE_APP_SERVER_URL,
      pageNo: 1,
      pageSize: 9,
      totalPage: 0,
      totalCount: 0,
      goodsList: [],
      activeName: '全部订单',
      activeKey: 1,
      alipay: '',
      toPayFlag: false,
      activeNameList: [
        {name: "全部订单", value: 1},
        {name: "未支付", value: 2},
        {name: "待远程", value: 3},
        {name: "已完成", value: 4},
        {name: "未评论", value: 5}],
    };
  },
  created() {
    this.loadGoods();
  },

  methods: {
    handleClick(tab, event) {
      let activeList = this.activeNameList.filter(i => i.name === this.activeName);
      if (activeList) {
        this.activeKey = activeList[0].value
      }
      this.loadGoods();
    },
    handleDelete(orderCode) {
      this.$confirm('确定删除该订单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delOrder(orderCode).then(res => {
          if (res.code == 200) {
            this.$modal.msgSuccess("删除成功")
            this.loadGoods();
          } else {
            this.$modal.msgError(res.msg)
          }
        })

      }).catch(() => {
      });
    },
    handleAfterSale(id) {
      this.$refs.afterSale.showDialog();
    },
    handleDownload(goodsCode) {
      getGoodDownload(goodsCode).then(res => {
        let downloadUrl = res.data.downloadUrl;
        if (downloadUrl) {
          if (downloadUrl.indexOf("?pwd=") == -1) {
            downloadUrl = downloadUrl + "?pwd=" + res.data.downloadKey;
          }
          //解析下载地址
          window.open(downloadUrl, '_blank');
        } else {
          this.$modal.msgError("项目下载地址异常，请联系管理员！")
        }
      })
    },
    handleAlipay(orderCode) {
      if (!this.toPayFlag) {
        this.toPayFlag = true;
        continueAlipay(orderCode).then(res => {
          this.alipay = res.payForm;
          // 防抖避免重复支付
          this.$nextTick(() => {
            // 提交支付表单
            this.$refs.alipayWap.children[0].submit();
            setTimeout(() => {
              this.toPayFlag = false;
            }, 500);
          })
        })
      }
    },
    handleComment(orderCode) {
      ``
      checkComment(orderCode).then(res => {
        if (res.code == 200 && res.data) {
          let routeUrl = this.$router.resolve({
            path: "/comment/goods",
            query: {orderCode: orderCode}
          });
          window.open(routeUrl.href, '_blank');
        } else {
          this.$modal.msgError("订单已评论")
        }
      })


    },
    pageNoHandle(currentPage) {
      this.pageNo = currentPage;
      this.loadGoods();
    },
    loadGoods() {
      let search = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        search: this.activeKey
      };
      getMyHistoryOrder(search).then(res => {
        this.goodsList = res.data.data;
        this.totalPage = res.data.totalPage;
        this.totalCount = res.data.totalCount;

      });
    },
    toDetails(goodsCode) {
      let routeUrl = this.$router.resolve({
        path: "/details",
        query: {goodsCode: goodsCode}
      });
      window.open(routeUrl.href, '_blank');
    }
  }
}
;
</script>

<style scoped>
.flex-warp {
  flex-wrap: wrap;
  flex-direction: row
}

::v-deep .el-card {
  margin-bottom: 24px;
}

::v-deep .el-card__body {
  padding: 0;
}

::v-deep.el-pagination {
  justify-content: center;
}

.time {
  font-size: 13px;
  color: #999;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.lasthead:hover {
  color: #0069d9 !important;
}

.details-header {
  height: 20px;
  background-color: rgba(240, 240, 240);
  font-size: 12px;
  color: rgba(180, 180, 180);;
}

.order-time {
  width: 45%;
  padding-left: 10px;
  float: left;
  display: flex;
  align-items: center; /* 在垂直方向上居中对齐 */
  justify-content: left; /* 在水平方向上居中对齐 */
  height: 20px;
}

.order-no {
  width: 40%;
  text-align: left;
  padding-left: 10px;
  float: left;
  display: flex;
  align-items: center; /* 在垂直方向上居中对齐 */
  justify-content: left; /* 在水平方向上居中对齐 */
  height: 20px;
}

.order-opt {
  width: 15%;
  float: left;
  padding-right: 10px;
  display: flex;
  align-items: center; /* 在垂直方向上居中对齐 */
  justify-content: right; /* 在水平方向上居中对齐 */
  height: 20px;
}

.details-content p {
  font-size: 13px;
  cursor: pointer;
}

.details-opt > p > a {
  font-size: 13px;
  cursor: pointer;
  margin-bottom: 5px;
  color: rgba(0, 68, 126, 0.72);
}


.details-opt > p > a:hover {
  color: #8a959f;
}

.details-img-name {
  width: 35%;
  float: left;
  text-align: left;
  height: 80px;
}

.details-img-name > div:first-child {
  text-align: left;
  float: left;
  width: 100px;
}

.details-img-name > div:last-child {
  text-align: left;
  float: left;
  width: calc(100% - 120px);
  display: flex;
  align-items: center; /* 在垂直方向上居中对齐 */
  justify-content: left; /* 在水平方向上居中对齐 */
  height: 80px;
  margin-left: 10px;
}

.details-mail {
  width: 13%;
  text-align: left;
  float: left;
  height: 80px;
  display: flex;
  align-items: center; /* 在垂直方向上居中对齐 */
  justify-content: center; /* 在水平方向上居中对齐 */
}

.details-remark {
  width: 10%;
  text-align: left;
  float: left;
  display: flex;
  align-items: center; /* 在垂直方向上居中对齐 */
  justify-content: center; /* 在水平方向上居中对齐 */
  height: 80px;
}

.details-price {
  width: 17%;
  text-align: left;
  float: left;
  display: flex;
  align-items: center; /* 在垂直方向上居中对齐 */
  justify-content: center; /* 在水平方向上居中对齐 */
  height: 80px;
}

.details-price > span:first-child {
  width: 100%;
  float: left;
}

.details-price > span:last-child {
  width: 100%;
}

.details-status {
  width: 10%;
  text-align: left;
  float: left;
  display: flex;
  align-items: center; /* 在垂直方向上居中对齐 */
  justify-content: center; /* 在水平方向上居中对齐 */
  height: 80px;
}

.details-opt {
  width: 15%;
  text-align: left;
  float: left;
  display: flex;
  align-items: center; /* 在垂直方向上居中对齐 */
  justify-content: right; /* 在水平方向上居中对齐 */
  height: 80px;
}

p {
  margin-bottom: 0px !important;
}

.details-img-name:hover {
  cursor: pointer;
}
</style>
