<template>
  <div>
    <div class="unique container">

      <div class="content-left">
        <div class="individual-resume-wrap">
          <div class="avatar-wrap">
            <img :src="avatar" width="80px" alt="">
          </div>
          <div class="resume-wrap">
            <div class="nickname">{{ nickName }}</div>
          </div>
        </div>
        <div class="tabs-wrap" @click="handleClickITab">
          <div class="i-tab" data-tabindex="2" :class="{itabactive:activeTabIndex==2}">我的源码</div>
          <div class="i-tab" data-tabindex="1" :class="{itabactive:activeTabIndex==1}">个人设置</div>
        </div>
      </div>
      <div class="content-right">
        <personal-setting v-if="activeTabIndex==1"/>
        <history-project v-if="activeTabIndex==2"/>
      </div>


    </div>
  </div>

</template>

<script>
import PersonalSetting from "@/views/personalCenter/modules/PersonalSetting";
import HistoryProject from "@/views/personalCenter/modules/HistoryProject";
import {mapGetters} from 'vuex'


export default {
  name: "PersonalCenter",
  components: {
    PersonalSetting,
    HistoryProject
  },
  data() {
    return {
      activeTabIndex: 2,
    };
  },
  created() {

  },
  computed: {
    ...mapGetters(['nickName', 'avatar'])
  },
  methods: {
    handleClickITab(event) {
      this.activeTabIndex = event.target.getAttribute("data-tabindex");
    }
  }
}
</script>

<style scoped>
/deep/ .el-dialog {
  min-width: 450px;
  max-width: 500px;
}

.container {
  display: flex;
}

.content-left {
  width: 100px;
  text-align: center;
}

.individual-resume-wrap {
  margin-bottom: 50px;
}

.tabs-wrap {
  display: flex;
  flex-direction: column;
}

.tabs-wrap .i-tab:hover {
  color: #409eff;
  cursor: pointer;
}

.tabs-wrap .i-tab {
  padding: 0 20px;
  height: 40px;
  box-sizing: border-box;
  line-height: 40px;
  display: inline-block;
  list-style: none;
  font-size: 14px;
  font-weight: 500;
  color: #303133;
  position: relative;
}

.tabs-wrap .itabactive {
  color: #409eff;
  border-right: 2px solid #409eff;
}

.content-left .individual-resume-wrap .nickname {
  color: #303133;
}

.individual-resume-wrap .resume-wrap {
  margin-top: 20px;
  text-align: center;
}

.content-right {
  width: calc(100% - 100px);
  border-left: 1px solid #E4E7ED;
  padding-left: 20px;
  min-height: calc(100vh - 586px);
}
</style>
