<template>
  <div>
    <!--====== Banner Start ======-->
    <section class="breadcrumb-area">
      <div class="container">
        <div class="content">
          <h2 class="breadd  wow fadeInUp" id="title-1">
            项目列表
          </h2>
          <ul class="breadcrumb-list  wow fadeInUp">
            <li><a>首页 /</a></li>
            <li>项目列表</li>
          </ul>
        </div>
      </div>
    </section>
    <!--====== Banner End ======-->
    <!--====== 开源项目内容 Start  ======-->
    <section class="unique themes producttwo categorry-three" style="padding-top:60px">
      <div class="container">
        <div class="row">
          <div class="col-lg-12  wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
            <div class="product-show">
              <div class="left-box">
								<span style="min-width: 260px;width: 17vw;">
                                  <el-input v-model="searchValue" placeholder="请输入内容"
                                            @keyup.enter.native="loadGoods"></el-input>
								</span>
                <el-button type="primary" icon="el-icon-search" style="margin-left: 10px;"
                           @click="loadGoods">搜索
                </el-button>
              </div>

              <div class="right-box" style="min-width:100px">
                <div class="language-select">
                  <el-select class="select-bar" v-model="sortBy" @change="loadGoods">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-tabs v-model="activeName1" @tab-click="loadGoods">
          <el-tab-pane v-for="item in categoryList" :label="item.name" :name="item.code" :key="item.code">
            <el-row type="flex" class="flex-warp align-items-center" :gutter="24">
              <el-col v-for="commodity in currentCategoryGoodsList" :sm="12" :md="8" :lg="6" :xl="6"
                      class="text-center">
                <el-card class="themes-box">
                  <div class="thumb">
                    <el-image style="width: 200pt;height: 120pt"
                              fit="contain"
                              :src="commodity.imgPath" alt="">
                      <div slot="placeholder" class="image-slot">
                        <div style="width: 200pt;height: 120pt;text-align: center;padding-top: 50pt;color: #8a959f">
                          加载中.....
                        </div>
                      </div>
                    </el-image>
                    <div class="button-box">
                      <a href="javascript:void(0)" class="link link-1"
                         @click="toDetails(commodity.goodsCode)"><i
                          class="far fa-eye"></i></a>
                      <a href="javascript:void(0)" class="link link-2" @click="buy(commodity.goodsCode)"><i
                          class="fas fa-cart-plus"></i></a>
                    </div>
                  </div>
                  <div @click="toDetails(commodity.goodsCode)" class="hand">
                    <p class="good-name">
                      <span class="tag-good-self" v-if="commodity.isOriginal==1">原创</span>
                      <span class="tag-good-open" v-if="commodity.isOriginal==0">精品</span>
                      <txt v-html="highlightKeywords(commodity.goodsName)"></txt>
                    </p>
                    <div class="tag-list">
                      <span class="tec-tag" v-for="tech in commodity.techNames">{{ tech }}</span>
                    </div>
                    <div class="goods-bottom">
                      <!-- 价格-->
                      <span class="goods-price">
                                <h3 class="subtitle">￥{{ numberToFixed(commodity.currentPrice, 2) }}</h3>
                      </span>
                      <span class="goods-time">
                        <!--                        {{ parseTime(commodity.publishTime, 'yyyy-MM-dd ') }}-->
                      </span>
                      <span class="goods-pl">
<!--                        <p>{{ commodity.commentCount > 0 ? commodity.commentCount + '条评论' : '暂无评论' }}</p>-->
              <el-rate
                  v-model="commodity.commentCount"
                  disabled
                  show-score
                  text-color="#ff9900"
                  score-template="{value}分">
                        </el-rate>
                      </span>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
        <div class="row">
          <div class="col-lg-12  wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
            <el-pagination
                class="pagination"
                background
                layout="prev, pager, next"
                @current-change="pageNoHandle"
                :page-count="totalPage">
            </el-pagination>
          </div>
        </div>
      </div>
    </section>
    <!--====== 开源项目内容 End  ======-->
  </div>
</template>

<script>
import {getCategory, getGoods} from '@/api/good';

export default {
  name: 'ProductList',
  data() {
    return {
      baseUrl: process.env.VUE_APP_SERVER_URL,
      activeName1: '',
      keyWordList: [],
      sortBy: '1',
      pageNo: 1,
      pageSize: 12,
      totalPage: 1,
      searchValue: '',
      categoryList: [],
      currentCategoryGoodsList: [],
      options: [{
        value: '1',
        label: '默认排序'
      }, {
        value: '2',
        label: '最新发布'
      }, {
        value: '3',
        label: '最早发布'
      }],
    };
  },
  mounted() {
    //加载分类
    getCategory().then(res => {
      if (res.code == 200) {
        this.categoryList = Object.freeze(res.data);
        this.searchValue = this.$route.params.keyWords;
        this.activeName1 = res.data[0].code;
        //查询商品
        this.loadGoods();
      }
    });
  },

  methods: {
    highlightKeywords(text) {
      let highlightedText = text;
      if (this.keyWordList && this.keyWordList.length > 0) {
        this.keyWordList.forEach(keyword => {
          const regex = new RegExp(`(${keyword})`, 'gi'); // 'gi' 表示全局匹配且忽略大小写
          highlightedText = highlightedText.replace(regex, '<span class="highlight">$1</span>');
        });
      }
      return highlightedText;
    },
    pageNoHandle(currentPage) {
      this.pageNo = currentPage;
      this.loadGoods();
    },

    loadGoods() {
      let search = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        search: this.activeName1 == 'all' ? '1 = 1' : 'category_code like "%' + this.activeName1 + '%"'
      };
      if (this.searchValue) {
        search.key = this.searchValue;
      }
      if (this.sortBy == 2) {//最新发布
        search.sortField = 'publish_time';
        search.sortOrder = 'desc';
      } else if (this.sortBy == 3) {//最早发布
        search.sortField = 'publish_time';
        search.sortOrder = 'asc';
      }
      getGoods(search).then(res => {
        this.currentCategoryGoodsList = res.data.data;
        this.totalPage = res.data.totalPage;
        this.keyWordList = res.data.keyWordList;
      });
    },
    toDetails(goodsCode) {
      let routeUrl = this.$router.resolve({
        path: '/details',
        query: {goodsCode: goodsCode}
      });
      window.open(routeUrl.href, '_blank');
    },
    buy(goodsCode) {
      let routeUrl = this.$router.resolve({
        path: '/buy',
        query: {goodsCode: goodsCode}
      });
      window.open(routeUrl.href, '_blank');
    }
  }
};
</script>

<style scoped>
.header-three .sticky-header .sticky-on .header-navigation {
  background: #547af8 !important;
}

p {
  margin-bottom: 0px
}

.flex-warp {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.unique .themes-box .main-content .last-part {
  display: block;
  padding-top: 0px;
}

::v-deep .select-bar .el-input__inner {
  border: 0 !important;
  width: 120px;
  font-size: 1.2em;
}

::v-deep.el-pagination {
  justify-content: center;
}

p {
  margin-bottom: 0px
}

.flex-warp {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.flex-warp {
  flex-wrap: wrap;
  flex-direction: row
}


.heroarea .single {
  background: url("~@/static/image/banner3-1.png");
}

.unique {
  padding: 50px 0px 50px;
}

.bannner.three .heroarea .single {
  padding: 150px 0px 150px;
  position: relative;
  z-index: 1;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}


/deep/ .el-card__body, .el-main {
  padding: 10px;
}
</style>
