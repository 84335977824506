<template>
  <div>
    <!--====== Banner Start ======-->
    <section class="breadcrumb-area">
      <div class="container">
        <div class="content">
          <h2 class="breadd  wow fadeInUp" id="title-1">
            帮助中心
          </h2>
          <ul class="breadcrumb-list  wow fadeInUp">
            <li><a>首页 /</a></li>
            <li>帮助中心</li>
          </ul>
        </div>
      </div>
    </section>

      <el-collapse v-model="activeNames" @change="handleChange" style="width: 60%;margin:0px auto"  class="wow fadeInUp">
        <el-card>
        <el-collapse-item name="0">
          <template slot="title">
                    <span class="q-title">
                   购买的项目包含什么？
                    </span>
          </template>
          <div>1.100%可以成功运行的实战项目源码</div>
          <div>2.实战项目相关说明文档以及安装调试教程文档</div>
          <div>3.专属客服联系方式，可以咨询技术相关问题</div>
        </el-collapse-item>
        <el-collapse-item name="1">
          <template slot="title">
                    <span class="q-title">
                    已经购买找不到项目下载方式？
                    </span>
          </template>
          <div>1.系统可能存在延时，可以耐心等待5分钟左右，通过以下方式进行获取</div>
          <div>2.下单时录入的邮箱，在成功支付后会向邮箱内发送下载方式</div>
          <div>3.已经登录用户，可以在个人中心中查看已经购买的项目</div>
          <div>4.通过【导航栏目】->【订单查询】输入【邮箱】以及【订单号】进行获取项目下载方式</div>
          <div>5.订单有效期【一年】，在一年内也可以自助通过以上方式获取项目下载方式</div>
        </el-collapse-item>
        <el-collapse-item name="2">
          <template slot="title">
                    <span class="q-title">
                   项目运行成功了，但是无法正常访问（无法登录管理员账号）？
                    </span>
          </template>
          <div>1.检查开发工具（IDEA、PYCHARM等）控制台，是否报错根据错误信息进行排查修改</div>
          <div>2.常见问题主要是数据库配置不正确，确保本地数据库可以正常访问，同时代码需要修改对应的数据库配置</div>
          <div>3.管理员账号在导入数据库时候自带一个，可以进入数据库用户表进行查看，确保自己没有修改，或登录修改后的账号
          </div>
        </el-collapse-item>
        <el-collapse-item name="3">
          <template slot="title">
                    <span class="q-title">
                  按照教程部署，为什么我的项目无法正常运行？
                    </span>
          </template>
          <div>1.在售项目经过工作人调试，已经确保可以正常运行</div>
          <div>2.安装教程主要起到参考作用，因不同电脑环境不同，请灵活应对调整切勿照搬照抄</div>
          <div>3.不想浪费时间在安装调试上，也可以根据文档联系客服都买远程服务</div>
        </el-collapse-item>
        <el-collapse-item name="4">
          <template slot="title">
                    <span class="q-title">
                我觉得这个功能不对？为什么这个功能不是这样的？这个功能作用意义？
                    </span>
          </template>
          <div>1.所有需求经过我们小伙伴前期分析所得，进而开发的功能模块</div>
          <div>2.如果对于功能模块设计、意义存在疑问质疑，还请自行百度分析</div>
          <div>3.成品项目提供开发完毕的功能模块，用户也可根据自己想法，自行去修改代码</div>
        </el-collapse-item>
        <el-collapse-item name="4-1">
          <template slot="title">
                    <span class="q-title">
             XXX技术是干什么用的？XXX框架怎么实现的？
                    </span>
          </template>
          <div>1.技术栈请参考项目配套说明文档</div>
          <div>2.技术栈具体功能、作用、实现请自行百度学习，再结合项目代码进行理解</div>
        </el-collapse-item>
        <el-collapse-item name="5">
          <template slot="title">
                    <span class="q-title">
               前后端不分离项目请求方式？如何进行页面和数据库交互？
                    </span>
          </template>
          <div>
            首先前台页面点击事件，触发JS脚本中的某些方法，然后JS方法针对数据初步校验参数，校验通过后携带数据信息通过AJAX异步请求后台接口，后台接口接收到请求和数据后再调用业务逻辑处理的方法进行处理数据，最后再调用执行操作数据库的相关方法，最终将数据响应给前端。响应传播方式与请求传播方式正好相反的。
          </div>
        </el-collapse-item>

        <el-collapse-item name="6">
          <template slot="title">
                    <span class="q-title">
              这个项目是怎么做的？软件开发流程是什么？
                    </span>
          </template>
          <div>
            正常企业项目首先会做需求分析规避风险，但是咱们项目基本没有那一步，因为不涉及商业应用。然后是根据需求进行建模，建立数据库；然后就是码代码的过程了，首先可以先设计好前端静态页面，页面设计好了，可以根据前台页面需要的数据格式，再去开发后台接口代码，然后通过AJAX获取后台接口数据，通过JS展示赋值渲染到前台静态页面，这样静态页面也就变成了动态页面了。动态加载数据的页面就可以叫动态页面哈。最后就是测试项目功能，测试BUG，修改BUG，反复测试，反复修改这样。
          </div>
        </el-collapse-item>
        <el-collapse-item name="7">
          <template slot="title">
                    <span class="q-title">
              修改了页面代码但是没有效果？页面莫名出现异常？
                    </span>
          </template>
          <div>
            <div>1.一般出现这种问题的原因是浏览器缓存或者端口问题。</div>
            <div>2.浏览器清除缓存，快捷键：ctrl+shift+del，清除全部时间的缓存记录</div>
            <div>3.代码修改端口，然后重启项目，同时清理浏览器缓存尝试</div>
          </div>
        </el-collapse-item>
        <el-collapse-item name="8">
          <template slot="title">
                    <span class="q-title">
              这个项目有些功能不符合我要求，可以帮我改改么？
                    </span>
          </template>
          <div>
            <div>1.项目本身BUG问题，可以反馈给客服，客服收到后会第一时间调整修改</div>
            <div>2.非项目本身BUG问题，是不会无偿协助修改的</div>
            <div>3.您在购买源码深度体验后，发现不能满足您的功能需求，可以提出自己的需求联系客服进行付费修改</div>
          </div>
        </el-collapse-item>
        </el-card>
      </el-collapse>

  </div>
</template>


<script>
export default {
  data() {
    return {
      activeNames: ['0', '1', '2', '3', '4', '5', '6', '7', '4-1', '8']
      // activeNames: []
    };
  },
  methods: {
    handleChange(val) {
      console.log(val);
    }
  }
}
</script>
<style>
.q-title {
  font-weight: bold;
  font-size: 16px;
}
</style>
