<template>
  <div class="msg-main">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>在线客服</span>
      </div>
      <div class="text item">
        <div class="chat-content">
          <span class="chat-item">
            <div class="chat-msg-img-left">
              <img src="@/static/image/customer-logo.jpeg" width="50" alt="">
            </div>
            <div class="chat-msg-content-left">
              <span class="chat-item-msg">
                <p style="max-width: 100%">
                  感谢您信任【是云猿实战】，我们将竭诚为服务，咨询内容包括项目咨询、定制化项目咨询、毕业设计咨询等。请您描述咨询项目名称以及所遇到的疑问。
                </p>
              </span>
            </div>
          </span>
          <span class="chat-item" v-for="item in hisMsgList">
                        <div class="chat-msg-img-left" v-if="item.type=='0'">
              <img src="@/static/image/customer-logo.jpeg" width="50" alt="">
            </div>
            <div class="chat-msg-content-left" v-if="item.type=='0'">
              <span class="chat-item-msg">
                <p style="max-width: 100%">
                         {{ item.msg }}
                </p>
              </span>
            </div>
            <div class="chat-msg-img-right" v-if="item.type=='1'"><img src="@/static/image/user-logo.jpg" width="50"
                                                                       alt="">
            </div>
            <div class="chat-msg-content-right" v-if="item.type=='1'">
              <span class="chat-item-msg">
                <p>
                  {{ item.msg }}
                </p>
              </span>
            </div>
          </span>
        </div>

      </div>
      <div style="height: 150px">
        <el-form
            @keyup.enter.native="sendMsg()">
          <el-input style="height: 100px"
                    type="textarea"
                    :rows="2"
                    :disabled="!isConnect"
                    placeholder="请输入内容"
                    v-model="msg">
          </el-input>
          <div style="text-align: right;width: 100%;padding-top: 5px">
            <el-button type="danger" plain @click="close" v-if="isConnect">退出</el-button>
            <el-button type="success" plain @click="open" v-if="!isConnect">连线</el-button>
            <el-button type="" plain @click="sendMsg" v-if="isConnect" @keyup.enter="sendMsg">发送</el-button>
          </div>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import {nanoid} from 'nanoid'
import Cookies from 'js-cookie'

export default {
  name: "chat",
  data() {
    return {
      websocket: "",
      isConnect: false,
      msg: '',
      serverId: '',
      curMsg: {},
      hisMsgList: [],
      timeoutObj: null,
      serverTimeoutObj: null,
    };
  },
  created() {
    let uid = Cookies.get('uid');
    if (uid) {
      this.open(uid);
    }
  },
  methods: {
    sendMsg() {
      if (!this.webSocket) {
        this.isConnect = false;
        this.$modal.msgError('系统繁忙，请重新连接客服')
        return;
      }
      if (!this.serverId) {
        this.$modal.msgError('请输重新连接客服');
        this.isConnect = false;
        return;
      }
      if (!this.msg) {
        this.$modal.msgError('请输入咨询内容');
        return;
      }
      this.webSocket.send(JSON.stringify({msg: this.msg, userId: Cookies.get('uid'), toUserId: this.serverId}))
      this.msg = ''
    },
    init(sessionId) {
      // 实例化socket
      // this.webSocket = new WebSocket("ws://127.0.0.1:10086/chat/online/" + sessionId);
      this.webSocket = new WebSocket("wss://shiyuncode.com/wss/chat/online/" + sessionId);
      // 监听socket连接
      this.webSocket.onopen = this.open
      // 监听socket错误信息
      this.webSocket.onerror = this.error
      // 监听socket消息
      this.webSocket.onmessage = this.getMessage
      // 监听socket断开连接的消息
      this.webSocket.close = this.close
    },
    open() {
      if (!this.isConnect) {
        let uid = Cookies.get('uid');
        if (!uid) {
          uid = nanoid(20)
          Cookies.set('uid', uid)
        }
        this.init(uid);
        this.isConnect = true;
      }
    },
    error() {
      this.isConnect = false;
      this.$modal.msgError('系统繁忙，请稍重新连接客服')
      console.log("连接错误")
    },
    getMessage(message) {
      this.heartCheckReset()
      this.heartCheckStart()
      let data = JSON.parse(message.data);
      let move = false;
      if (data.hisMsg) {
        this.hisMsgList = data.hisMsg;
        move = true;
      } else if (data.curMsg) {
        let curMsg = data.curMsg;
        this.hisMsgList.push(curMsg);
        move = true;
      } else if (data.success && data.success == "success") {
        this.serverId = data.serverId;
        this.isConnect = true;
        this.$modal.msgSuccess('客服连接成功，请继续咨询')
      } else if (data.success && data.success == "error") {
        this.isConnect = false;
        this.$modal.msgError('客服繁忙，请稍后重试')
      } else if (data.OffLine) {
        this.isConnect = false;
        this.$modal.msgError('客服已离线，请稍后连接')
      } else if (data.heartData) {
        // console.log("防止断连：心跳机制")
      }
      if (move) {
        this.scrollToBottom();
      }

    },
    close() {
      if (this.isConnect) {
        this.isConnect = false;
      } else {
        this.reconnect()
      }
    },
    scrollToBottom() {//保持滚动条最下方
      this.$nextTick(() => {
        let container = this.$el.querySelector(".chat-content");
        container.scrollTop = container.scrollHeight;
      })
    },
    //若链接中断30秒后创建新的链接
    reconnect() {
      if (this.lockReconnect) return ''
      this.lockReconnect = true
      setTimeout(() => {
        this.join()
        this.lockReconnect = false
        //若链接中断30秒后创建新的链接
      }, 30000)
    },
    //清空定时器
    heartCheckReset() {
      clearTimeout(this.timeoutObj);
      clearTimeout(this.serverTimeoutObj)
    },
    // 开启定时器
    heartCheckStart() {
      var self = this;
      this.timeoutObj = setTimeout(function () {
        //这里发送一个心跳，后端收到后，返回一个心跳消息，
        //onmessage拿到返回的心跳就说明连接正常
        self.webSocket.send("HEARTBEAT");
        self.serverTimeoutObj = setTimeout(function () { //如果超过一定时间还没重置，说明后端主动断开了
          self.webSocket.close();
        }, 30000)
      }, 30000)

    },
  }
}
</script>

<style scoped>
.msg-main {
  width: 100vw;
  height: 100vh;
  background-color: #dedede;
  align-items: center;
  justify-content: center;
  display: flex;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

/deep/ .el-card {
  transition: 0s !important;
}

/deep/ .el-textarea__inner {
  height: 100px;
}

.box-card {
  max-width: 800px;
  max-height: 736px;
  min-height: 500px;
  width: 50vw;
  height: 46vw;
  /*overflow: auto;*/
}

.chat-content {
  max-height: 468px;
  min-height: 238px;
  height: calc(46vw - 261px);
  overflow: auto;
}

.box-card p {
  word-wrap: break-word;
  word-break: break-all;
  margin-bottom: 0rem;
}

.chat-msg-img-left {
  width: 50px;
  height: 50px;
  float: left;
}

.chat-msg-content-left {
  max-width: calc(100% - 120px);
  float: left;
  margin-left: 10px;
}

.chat-msg-img-right {
  width: 50px;
  height: 50px;
  float: right;
  /*margin-top: 10px;*/
}

.chat-msg-content-right {
  max-width: calc(100% - 120px);
  float: right;
  margin-right: 10px;
}

.chat-item {
  width: 100%;
  margin-bottom: 7px;
}

.chat-item-msg {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #EBEEF5;
  background-color: #FFF;
  overflow: hidden;
  transition: .3s;
}
</style>