<template>
  <div>
    <!-- 页面主体START -->
    <section id="main" class="card">
      <h1></h1>
      <el-form accept-charset="utf-8" data-view="loginView">

        <div class="clearfix" data-propertyname="username" data-controltype="username">
          <input type="text" placeholder="输入帐号" v-model="obj.username" data-required="required"
                 autocomplete="off">
        </div>
        <div class="clearfix" data-propertyname="password" data-controltype="Password">
          <input type="password" v-model="obj.password" placeholder="输入密码" data-required="required"
                 autocomplete="off">
        </div>
        <div class="clearfix" data-propertyname="password" data-controltype="Password">
          <input type="password" v-model="obj.repassword" placeholder="再次输入密码" data-required="required"
                 autocomplete="off">
        </div>
        <div class="clearfix" data-propertyname="email" data-controltype="Email">
          <input type="email" name="pd" v-model="obj.mail" placeholder="请输入邮箱" data-required="required"
                 autocomplete="off">
        </div>
        <div class="clearfix" data-propertyname="checkCode" data-controltype="CheckCode" style="display: flex">
          <input type="text" name="checkCode" style="width: 60%" v-model="obj.verCode"
                 placeholder="请输入邮箱验证码" data-required="required"
                 autocomplete="off">
          <el-button type="success" :disabled="isSend" class="checkBtn" @click="sendCheckCode()">{{ sendMsg }}
          </el-button>
        </div>
        <div class="clearfix" data-propertyname="checkCode" data-controltype="CheckCode" style="display: flex">
          <img @click="loadCaptImg" :src="'data:image/gif;base64,'+captImgUrl"
               style="height: 46px;margin: 9px 0;">
          <input type="text" v-model="obj.code" style="width: 80%" placeholder="请输入计算结果"
                 data-required="required"
                 autocomplete="off">
        </div>
        <div class="clearfix btn_login" data-propertyname="submit" data-controltype="Botton">
          <input type="button" @click="reg()" value="注册">
        </div>

      </el-form>
    </section>
  </div>
</template>

<script>

import {sendEmailCode, getCaptImg, register} from "../../api/user";
import store from '@/store'

export default {
  name: "register",
  data() {
    return {
      isSend: false,
      sendTimer: null,
      sendMsg: '发送验证码',
      captImgUrl: '',
      obj: {
        mail: '',
        verCode: '',//邮箱验证码
        password: '',
        repassword: '',
        username: '',
        code: ''//验证码
      }
    }
  },
  created() {
    this.loadCaptImg()
  },
  methods: {
    loadCaptImg() {
      getCaptImg().then(res => {
        this.captImgUrl = res.img
      })
    },
    reg() {
      if (!this.obj.username || this.obj.username == '') {
        this.$message('请正确填写用户名')
        return
      }
      if (!this.obj.password || this.obj.password == '') {
        this.$message('请正确填写密码')
        return
      }
      if (!this.obj.repassword || this.obj.repassword == '') {
        this.$message('请重复填写密码')
        return
      }
      if (this.obj.password !== this.obj.repassword) {
        this.$message('二次密码输入不一致')
        return
      }
      if (!this.validateEmail(this.obj.mail)) {
        return
      }
      if (!this.obj.code || this.obj.code == '') {
        this.$message('请填写验证码')
        return
      }
      if (!this.obj.verCode || this.obj.verCode == '') {
        this.$message('请填写邮箱验证码')
        return
      }
      register(this.obj).then(res => {
        if (res.code == 200) {
          this.$store.dispatch("RegistLogin", this.obj).then(() => {
            store.dispatch("GetInfo", null).then(() => {
              this.nickName = store.getters.nickName;
              this.$modal.msgSuccess("注册成功");
              this.$router.replace('/')
            })
          })
        }
      })
      this.loadCaptImg()
    },
    //发送验证码
    sendCheckCode() {
      if (!this.obj.mail || this.obj.mail.trim() == '') {
        this.$modal.msgWarning("请输入邮箱")
        return
      }
      this.isSend = true
      this.sendMsg = '正在发送...'
      sendEmailCode({receiveMail: this.obj.mail}).then(res => {
        if (res.code == 200) {
          this.$modal.msgSuccess('邮箱发送成功')
          clearInterval(this.sendTimer)
          let timers = 60;
          this.sendTimer = setInterval(() => {
            if (timers == 0) {
              clearInterval(this.sendTimer)
              this.isSend = false
              this.sendMsg = '发送验证码'
              return
            }
            this.sendMsg = timers + "秒后重试"
            timers--;
          }, 1000)
        } else {
          this.$modal.msgError('邮箱发送失败')
        }
      })
    },
    validateEmail(value) {
      if (value === '') {
        this.$message('请正确填写邮箱')
        return false
      } else {
        let reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        if (!reg.test(value)) {
          this.$message('请输入有效的邮箱')
          return false
        }
        return true;
      }
    }
  }
}
</script>

<style scoped>
.checkBtn {
  margin: 9px 0px 9px 10px;
  width: 35%;
}

a {
  background-color: transparent;
  outline: 0;
  text-decoration: none
}

a:hover {
  text-decoration: underline
}

p {
  margin: 0
}

p + p {
  margin-top: 10px
}

img {
  border: 0;
  vertical-align: top;
  display: inline-block
}

button, input, optgroup, select, textarea {
  font-family: "Hiragino Sans GB", "Microsoft Yahei", SimSun, Arial;
  font-size: 14px;
  margin: 0;
  padding: 0;
  border: 1px solid #cecece
}

button:focus, input:focus, optgroup:focus, select:focus, textarea:focus {
  outline: 0
}

input[type=text], input[type=password], input[type=email] {
  color: #b9b9b9;
  border: 1px solid #cecece;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  padding: 12px;
  margin: 9px 0
}

label, select, button, input[type=button], input[type=reset], input[type=submit], input[type=radio], input[type=checkbox] {
  cursor: pointer
}

input[type=checkbox], input[type=radio] {
  padding: 0
}

input[type=submit], input[type=reset], input[type=button], button {
  -webkit-appearance: none
}

i {
  font-style: normal
}

ul {
  margin: 0;
  padding: 0
}

li {
  list-style: none
}


.clearfix {
  *zoom: 1
}

.clearfix:before, .clearfix:after {
  content: "";
  display: table
}

.clearfix:after {
  clear: both
}

@media screen and (max-width: 600px) {
  #header a {
    margin: 0 auto;
    position: relative;
    left: -18px
  }
}

#main {
  width: 530px;
  background: #fff;
  padding: 46px 80px;
  position: relative;
  left: 50%;
  top: 158px;
  margin: 0 0 280px -260px;
  z-index: 2
}


#footer p a {
  font-size: 14px;
  color: #fff;
  text-align: center;
  margin: 0 12px
}

#footer p {
  color: #858687;
  font-size: 12px;
  text-align: center;
  margin: 5px 0
}

#footer div {
  text-align: center
}

#footer div span {
  color: #a9a9a9
}

#main h1 {
  font-size: 14px;
  color: #555;
  text-align: center;
  width: 64px;
  background: url("~@/static/image/bl.svg") no-repeat;
  background-size: 64px;
  height: 50px;
  padding-top: 40px;
  margin: 0 auto 15px
}

#main input[type=button] {
  border: 0;
  height: 46px;
  width: 100%;
  color: #fff;
  font-size: 16px;
  text-align: center;
  background: #15b7ff;
  -webkit-border-radius: 23px;
  -moz-border-radius: 23px;
  border-radius: 23px;
  margin: 18px 0
}

input,
textarea,
select {
  width: 100%;
  height: 46px;
  background-color: #fff;
  color: #81a3bb;
  padding: 0 30px;
  font-size: 16px;
  border: none;
}

#main .btn_login {
  border-bottom: 1px solid #e3e4e5
}

#main .goregister {
  margin-top: 24px
}

#main .goregister a {
  float: right;
  padding: 3px 15px;
  border: 1px solid #15b7ff;
  color: #15b7ff;
  margin-top: -5px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 15px
}

#main .goregister a:hover {
  text-decoration: none
}

#main .verifyCodeInput input {
  display: inline;
  width: 137px;
  margin-right: 6px
}

#main .verifyCodeInput .yzm {
  width: 100px;
  height: 46px;
  vertical-align: middle;
  display: inline;
  margin: 10px 0
}


@media only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 240dpi), only screen and (min-resolution: 2dppx) {
  #main h1 {
    /*background: url(../images/login@2x_38cc706.png) no-repeat;*/
    background-size: 64px 64px
  }
}

@media screen and (max-width: 600px) {
  #main {
    box-sizing: border-box;
    width: 92%;
    margin: 4%;
    padding: 8% 4%;
    left: auto;
    top: auto
  }

  #main input[type=text], #main input[type=password] {
    box-sizing: border-box;
    width: 100%
  }

  #main .verifyCodeInput input[type=text] {
    width: 56%;
    margin-right: 4%;
    float: left
  }

}

.card {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 1px solid #EBEEF5;
  background-color: #FFF;
  overflow: hidden;
  color: #303133;
  transition: .3s;
}
</style>
