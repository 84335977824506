<template>
  <el-dialog title="售后服务" :visible.sync="dialogVisible" width="350px">
    <div>
      <div style="margin: 10px 0 0 0;text-align:center;">
        <img src="@/assets/images/vx.png" style="width: 140px"/>
        <p style="color: #A1A1A1;font-size:10px;">售后微信</p>
      </div>
      <div style="margin: 0 0;text-align:center;">
        <p style="color: #8a959f;font-size:13px;">添加客服微信，进一步沟通售后，为您提供合理的问题解决方案。</p>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'AfterSale',
  data() {
    return {
      dialogVisible: false,//弹框默认显示与否
    };
  },
  methods: {
    showDialog() {//显示Dialog弹框
      this.dialogVisible = true
    },
    closeDialog() {//弹框的关闭方法
      this.dialogVisible = false
    },
    SubmitEvent() {//确定事件

    }
  }
}
</script>

