<template>
    <div>
        <footer class="footer-area two">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-lg-4 col-md-8 wow fadeInUp" data-wow-delay="0.2s"
                         style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;">
                        <div class="footer-box one" style="margin-top: 50px">
                            <div class="logo">
                                <img src="@/static/picture/logo-2.svg" style="max-width: 150px;" alt="">
                            </div>
                            <p class="text">
                              是云猿实战致力于提供丰富多样的实战源码，满足各个领域的需求，包括网站开发、移动应用、数据可视化等！如项目侵犯您的权益，还请您及时联系微信客服进行协商处理。
                            </p>
                            <p class="text" style="padding-bottom: 0px;margin-bottom: 0px">
                                如需商业合作联系下方客服微信：
                            </p>
                            <div class="social">
                                <span><i class="fab fa-weixin"></i>&emsp;amcloud_os</span><br>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-8 wow fadeInUp" data-wow-delay="0.3s"
                         style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                        <div class="footer-box two">
                            <h4 class="lasthead">内部链接</h4>
                            <div class="footer-list">
                                <ul>
                                    <li><a href="/"> 首页 </a></li>
                                    <li><a href="/productlist"> 实战源码 </a></li>
<!--                                    <li><a href="/graduation"> 毕设一对一 </a></li>-->
                                    <li><a href="/help"> 帮助中心 </a></li>
                                    <li><a href="/course"> 环境教程 </a></li>
<!--                                    <li><a href="/my"> 个人中心 </a></li>-->
<!--                                    <li><a href="/register"> 前往注册 </a></li>-->
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-8 wow fadeInUp" data-wow-delay="0.4s"
                         style="visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;">
                        <div class="footer-box three">
                            <h4 class="lasthead">快速链接</h4>
                            <div class="footer-list">
                                <ul>
                                    <li><a href="https://space.bilibili.com/429767685?spm_id_from=333.1007.0.0"
                                           target="_blank">
                                        bilibili </a></li>
                                    <li><a href="https://blog.csdn.net/m0_47220500?spm=1019.2139.3001.5343"
                                           target="_blank">
                                        CSDN </a></li>
                                    <li><a href="https://www.baidu.com/" target="_blank"> 百度一下 </a></li>
                                    <li><a href="https://cn.bing.com/" target="_blank"> 必应搜索 </a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <div class="copyright two">
            <div class="container">
                <div class="row">
                    <div class="col-12  wow fadeInUp text-center"
                         style="visibility: visible; animation-name: fadeInUp;">
                        <p class="text">Copyright © 2023.是云猿实战 All rights reserved. <a style=" color: #8a8f9c;"
                                                                                       href="https://beian.miit.gov.cn/"
                                                                                       target="_blank">皖ICP备2022017493号-1</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'Footer'
  };
</script>

<style scoped>
    .footer-area {
        background-color: #fff;
        /*padding: 90px 0 120px;*/
        overflow: hidden;
    }

    .footer-area .footer-box {
        position: relative;
    }

    .footer-area .footer-box::before {
        position: absolute;
        top: 50%;
        right: 0px;
        content: '';
        width: 2px;
        height: 200%;
        background: #f8f8f8;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        display: none;
    }

    .footer-area .footer-box.one::before {
        right: -8%;
    }

    .footer-area .footer-box.two::before {
        right: -16%;
    }

    .footer-area .footer-box.three::before {
        right: 6%;
    }

    .footer-area .footer-box.none::before {
        display: none;
    }

    .footer-area .footer-box {
        margin-top: 30px;
    }

    .footer-area .footer-box .logo {
        padding-bottom: 34px;
    }

    .footer-area .footer-box h4.lasthead {
        padding-bottom: 20px;
    }

    .footer-area .footer-box p.text {
        font-size: 14px;
        line-height: 1.71;
    }

    .footer-area .footer-box ul {
        padding-left: 20px;
        list-style-type: circle;
    }

    .footer-area .footer-box ul li {
        padding-top: 8px;
    }

    .footer-area .footer-box ul li a {
        font-size: 14px;
        line-height: 1.71;
        color: #6f7987;
    }

    .footer-area .footer-box ul li a:hover {
        color: #547af8;
    }

    .footer-area .footer-box ul li:hover {
        color: #547af8;
    }

    .footer-area .footer-box ul li.active {
        color: #547af8;
    }

    .footer-area .footer-box ul li.active a {
        color: #547af8;
    }

    .footer-area .footer-box .social {
        margin-top: 5px;
        color: #a1a4af;
    }


    .footer-area .footer-box .social a {
        margin-right: 8px;
        border-radius: 3px;
        background-color: #fdfdfd;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 2.66;

        display: inline-block;
        -webkit-transition: 0.3s;
        transition: 0.3s;
    }

    .footer-area .footer-box .social a:hover {
        background: #547af8;
        color: #fff;
    }

    .copyright {
        padding: 30px 0px 10px;
        background: #fdfdfd;
    }

    .copyright p.text {
        color: #6f7987;
    }

    .copyright p.text a {
        color: #547af8;
    }

    .copyright p.text a:hover {
        text-decoration: underline;
    }

    .footer-area.two {
        background-color: #03142c;
    }

    .footer-area.two .footer-box::before {
        background: #102037;
    }

    .footer-area.two .footer-box h4.lasthead {
        color: #ffffff;
    }

    .footer-area.two .footer-box p.text {
        color: #bec2ce;
    }

    .footer-area.two .footer-box ul li a {
        font-size: 14px;
        line-height: 1.71;
        color: #bec2ce;
    }

    .footer-area.two .footer-box ul li a:hover {
        color: #ffffff;
    }

    .footer-area.two .footer-box ul li:hover {
        color: #ffffff;
    }

    .footer-area.two .footer-box ul li.active {
        color: #ffffff;
    }

    .footer-area.two .footer-box ul li.active a {
        color: #ffffff;
    }

    .footer-area.two .footer-box .social a {
        background-color: #101f34;
        color: #b1c1d6;
    }

    .footer-area.two .footer-box .social a:hover {
        background: #547af8;
        color: #fff;
    }

    .copyright {
        background: #011025;
    }

    .copyright p.text {
        color: #8a8f9c;
    }

    .copyright p.text a {
        color: #fff;
    }

    .copyright p.text a:hover {
        text-decoration: underline;
    }
</style>
